export default {
  loginValidation: {
    email: [
      { required: true, message: 'Please enter your E-mail', trigger: 'blur' },
      {
        required: true,
        message: 'Please enter a valid E-mail',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    password: [
      { required: true, message: 'Please enter a password', trigger: 'blur' },
      { min: 6, max: 10, message: 'Length should be 6 to 8', trigger: 'change' }
    ]
  },
  forgotPasswordValidation: {
    email: [
      { required: true, message: 'Please enter your E-mail', trigger: 'blur' },
      {
        required: true,
        message: 'Please enter a valid E-mail',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ]
  },
  changePasswordValidation: {
    password: [
      { required: true, message: 'Please enter current password', trigger: 'blur' },
      { min: 6, max: 10, message: 'Length should be 6 to 8', trigger: 'change' }
    ],
    new_password: [
      { required: true, message: 'Please enter a new password', trigger: 'blur' },
      { min: 6, max: 10, message: 'Length should be 6 to 8', trigger: 'change' }
    ],
    confirm_password: [
      { required: true, message: 'Please enter confirm password', trigger: 'blur' },
      { min: 6, max: 10, message: 'Length should be 6 to 8', trigger: 'change' }
    ]
  },
  signupValidation: {
    email: [
      { required: true, message: 'Please enter your E-mail', trigger: 'blur' },
      {
        required: true,
        message: 'Please enter a valid E-mail',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    firstName: [
      { required: true, message: 'Please enter first name', trigger: 'blur' }
    ],
    lastName: [
      { required: true, message: 'Please enter last name', trigger: 'blur' }
    ],
    phone: [
      { required: true, message: 'Please enter phone number', trigger: 'blur' }
    ],
    clientName: [
      { required: true, message: 'Please enter client name', trigger: 'blur' }
    ]
  },
  newUser: {
    email: [
      { required: true, message: 'Please enter your E-mail', trigger: 'blur' },
      {
        required: true,
        message: 'Please enter a valid E-mail',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    first_name: [
      { required: true, message: 'Please enter first name', trigger: 'blur' }
    ],
    last_name: [
      { required: true, message: 'Please enter last name', trigger: 'blur' }
    ]
  },
  categoryValidation: {
    title: [
      { required: true, message: 'Please input title', trigger: 'blur' }
    ],
    parentCategory: [
      { required: false, message: 'Please select category', trigger: 'blur' }
    ],
    description: [{ required: false }],
    image: [
      { required: true, message: 'Image is required', trigger: 'change' }
    ]
  },
  subCategoryValidation: {
    title: [
      { required: true, message: 'Please input title', trigger: 'blur' }
    ],
    parentCategory: [
      { required: false, message: 'Please select category', trigger: 'blur' }
    ],
    description: [{ required: false }],
    image: [{ required: false }]
  },
  courseValidation: {
    title: [
      { required: true, message: 'Please input title', trigger: 'change' }
    ],
    category: [
      { required: true, message: 'Please select category', trigger: 'change' }
    ],
    type: [
      { required: true, message: 'Please select course type', trigger: 'change' }
    ],
    validity: [
      { required: true, message: 'Please select course validity', trigger: 'change' }
    ],
    image: [
      { required: true, message: 'Please select course image', trigger: 'change' }
    ],
    assessor: [
      { required: true, message: 'Assessor name is required', trigger: 'blur' }
    ]
  },
  outcomeValidation: {
    outcome: [
      { required: true, message: 'Course outcome is required', trigger: 'blur' }
    ]
  },
  quizValidation: {
    name: [
      { required: true, message: 'Quiz name is required', trigger: 'blur' }
    ],
    description: [{ required: false }],
    start_time: [{ required: false }],
    end_time: [{ required: false }],
    no_of_attempt: [{ required: false }],
    review: [{ required: false }],
    shuffle: [{ required: false }],
    no_of_questions: [{ required: false }],
    time_limit: [{ required: false }]
  },
  quizQuestionValidation: {
    name: [
      { required: true, message: 'Question name is required', trigger: 'blur' }
    ]
  },
  clientValidation: {
    client_name: [
      { required: true, message: 'Client name is required', trigger: 'blur' }
    ],
    first_name: [
      { required: true, message: 'First Name is required', trigger: 'blur' }
    ],
    last_name: [
      { required: true, message: 'Last Name is required', trigger: 'blur' }
    ],
    email: [
      { required: true, message: 'Please Enter your E-mail', trigger: 'blur' },
      {
        required: true,
        message: 'Please Enter a Valid E-mail',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    phone: [
      { required: false, message: 'Phone number is required', trigger: 'blur' }
    ]
  },
  clientSubscriptionValidation: {
    paymentType: [
      { required: true, message: 'Please select payment type', trigger: 'blur' }
    ]
  },
  groupCourseValidation: {
    name: [
      { required: true, message: 'Please Enter Name', trigger: 'blur' }
    ],
    description: [{ required: false }]
  },
  personalDetailsValidation: {
    first_name: [
      { required: true, message: 'First name is required', trigger: 'blur' }
    ],
    last_name: [
      { required: true, message: 'Last name is required', trigger: 'blur' }
    ],
    email: [
      { required: true, message: 'E-mail is required', trigger: 'blur' },
      {
        required: true,
        message: 'Please Enter a Valid E-mail',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    phone: [
      { required: true, message: 'Phone number is required', trigger: 'blur' }
    ],
    jobcode: [
      { required: true, message: 'Job Code is required', trigger: 'blur' }
    ],
    postcode: [
      { required: true, message: 'Post Code is required', trigger: 'blur' }
    ]
  },
  helpCenterValidation: {
    email: [
      { required: true, message: 'Please Enter your E-mail', trigger: 'blur' },
      {
        required: true,
        message: 'Please Enter a Valid E-mail',
        trigger: 'change',
        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }
    ],
    message: [
      { required: true, message: 'Please Enter a Message', trigger: 'blur' }
    ]
  },
  paymentKeyValidation: {
    key: [
      { required: true, message: 'Key or client id is required', trigger: 'blur' }
    ],
    secret: [
      { required: true, message: 'Secret is required', trigger: 'blur' }
    ]
  },
  courseCompleteEmail: {
    subject: [
      { required: true, message: 'Email subject is required', trigger: 'blur' }
    ]
  },
  appSettingsValidation: {
    youtube_url1: [
      { required: true, message: 'Youtube URL is required', trigger: 'blur' }
    ],
    youtube_url2: [
      { required: true, message: 'Youtube URL is required', trigger: 'blur' }
    ],
    youtube_url3: [
      { required: true, message: 'Youtube URL is required', trigger: 'blur' }
    ]
  }
}
